/* globals must be listed below to pass JSHint */
/* globals getRequestPath, searchListForItem, showAlert, renewServerSession, requestTimeout,
 showAjaxLoading, hideAjaxLoading, sortByProperty, searchList, isNumeric, searchArrayForObject, clearAuthorisation,
 getBarcodeMapping, getIncidentCodes, getProgramAllocationTypes, getProgramTransactionCode, console, isPickingProgram,
  scanLocation, localforage, sessionValidate, movement_casks, previous_scan:true, pickingAllocation, padNumber,
  countStoredTransactions, createBarcodeObject, localforage, getUserCompany, getScannerLocationCode, getSessionUserId,
 isSessionValid, getScannerId, getProgramName, hasProgramParameter, setLastIncident, getScannerCompany, scanningContainer,
 getCurrentPage, getSessionUser, getIncidentById, areRealPalletsInUse, VesselBarcodeType */
var sending = false; //When sending transactions this is set to true, to stop asynchronous sending.

/**
 * @description Temp function, to verify that all save transaction calls correctly send a callback.
 * @param callback
 * @returns {*|boolean}
 */
function isCallbackDefined(callback) {
	"use strict";
	return callback && {}.toString.call(callback) === '[object Function]';
}

/**
 * @description Internal to transaction.js, this saves transaction objects to localStorage.
 * @param transactionObject
 * @param callback
 * @returns boolean True if the transaction is saved to LS.
 */
function saveTransaction(transactionObject, callback) {
  "use strict";
	if (!isCallbackDefined(callback)) {
		console.error("TRANSACTION CANNOT SAVE - CODE CANNOT HANDLE ACTIONS");
		return false;
	}
	// Ensure the audio is unlocked before the async call
  	unlockAudio();
	//if we cannot save the user must stop and do something. Callback function decides.
  	localforage.setItem(transactionObject.transactionId,transactionObject).then(function() {
    	callback(true);
	}).catch(function(error) {
		console.log("Error. Transaction cannot be saved due to insufficient space. ", error);
		console.log(transactionObject.transactionId, " CODE:",(transactionObject.transactionCode)? transactionObject.transactionCode:"--");
		console.log("Barcode:", (transactionObject.barcode)? transactionObject.barcode:"--", "CaskId:", (transactionObject.caskId)? transactionObject.caskId:"--");
		showAlert("Cannot save transaction. Insufficient space.", {type: "error", callback: function() {
			callback(false);
		}});
	});
}

/**
 * @description Add the scanned entity to the movements list, and record the last scan.
 * @param barcodeObject
 */
function addToMovementCasks(barcodeObject) {
	"use strict";
	if(barcodeObject.barcode !== "" && barcodeObject.caskId === "") {
		movement_casks.push(barcodeObject.barcode);
		previous_scan = barcodeObject.barcode;
	} else {
		movement_casks.push(barcodeObject.caskId);
		previous_scan = barcodeObject.caskId;
	}
}
/**
 * @description creates a basic transaction from the previous scan. Doesn't support Allocation, Incident code, Trailer, or Disgorge Line
 *       Doesn't push to movement_casks for previous checks
 *       Only used in build pallet when also stowing - creating a stow based on the last cask built.
 * @param transactionCode {String} Transaction code for the transaction
 * @param callback {successCallback} Function to run once the transaction has been saved
 */
function createFollowUpTransaction(transactionCode, callback) {
  "use strict";
  console.log("Creating a ", transactionCode, " transaction...");
  console.log(previous_scan);
  // Note the input field here is "generatedInput". This skips SCANNED or DOUBLE scan checks
	// Also this may produce a different barcode than the one originally scanned based on the remap from a custom scan
  var barcodeObject = createBarcodeObject(previous_scan, "generatedInput", "");
  if (!barcodeObject.passed) {
    callback(false);
    return;
  }

	var transaction = createBaseTransaction({
		transactionCode: transactionCode,
		warehouse: scanLocation.warehouse,
		bay: scanLocation.bay,
		rack: scanLocation.rack,
		level: scanLocation.level,
		direction: "B",
		barcode: barcodeObject.barcode,
		caskId: barcodeObject.caskId,
		scanIndicator: "S"
	});

  saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description saves the barcode and transaction details
 * @param {Object} barcodeObject - The barcode object scanned
 * @param {Object} errorStatus - the error object produced from picking lists
 * @param {String} eventCode   - An event code to save against the transaction
 * @param callback
 */
function saveGenericTransaction(barcodeObject, errorStatus, eventCode, callback) {
  "use strict";
  if(!isSessionValid()) {
		callback(false);
		return;
	}

  //save barcode as transaction. Create the base transaction and add other appropriate values.
	var transaction = createBaseTransaction({});

    // todo refactoring the barcode we would need to apply the SWA values somewhere here. possibly in a function
	// A couple other transactions save barcodes so would need to save SWA values.
	transaction.scanIndicator = barcodeObject.entryType;
	transaction.warehouse = scanLocation.warehouse;
	transaction.bay = scanLocation.bay;
	transaction.rack = scanLocation.rack;
	transaction.level = scanLocation.level;
	transaction.direction = scanLocation.direction;
	transaction.locationIndicator = scanLocation.scanned;
	transaction.barcode = barcodeObject.barcode;
	transaction.caskId = barcodeObject.caskId;
	transaction.eventCode = eventCode;

	// build pallet transactions will take pallet no from the current pallet
	// all else will need to take pallet no from the barcodeObject.palletNo property.
  if(localStorage.getItem("currentPallet")) {
  	// If using real pallets set the palletNo property else set the virtual scannerPallet property
	if (areRealPalletsInUse()) {
		transaction.palletNo = localStorage.getObject("currentPallet").number;
	} else {
		transaction.scannerPallet = localStorage.getObject("currentPallet").number;
	}
  } else {
  	transaction.palletNo = barcodeObject.pallet.palletNo; // For scanning pallets for movements etc.
  }

  if(localStorage.getItem("scanTrailer")) {
    transaction.trailerId = localStorage.getItem("scanTrailer");
  }

  // Used in labelling screen where a make and a batch are entered, then caskIDs are scanned
  if(localStorage.getItem("scanBatch")) {
    transaction.batchId = localStorage.getItem("scanBatch");
  }
  if(localStorage.getItem("scanMake")) {
    transaction.make = localStorage.getItem("scanMake");
  }

  // if its a picking program then the allocation and line are also set.
	//todo cater for tuns
  if (isPickingProgram()) {
    switch(pickingAllocation.type) {
      case "DESPATCH":
        transaction.despatchId = pickingAllocation.number;
        transaction.container = pickingAllocation.van;
        transaction.sealOne = pickingAllocation.seal1;
        transaction.sealTwo = pickingAllocation.seal2;
        transaction.sealThree = pickingAllocation.seal3;
        break;
      case "ORDERLINE":
	  case "OPERATION":
      case "CASK":
        transaction.requestNumber = pickingAllocation.number;
        break;
      case "RECEIPT":
        break;
      default:
        transaction.requestNumber = pickingAllocation.number;
        break;
    }
    if ( getProgramName().toUpperCase() === "DISGORGE" || hasProgramParameter("LINE"))
      transaction.disgorgeLine = localStorage.getItem("disgorgeline");

    if (errorStatus) {
      // we must have an error.
      transaction.status = "Error";
      transaction.incidentCode = errorStatus.code;
      transaction.errorText = errorStatus.text;

			raiseAuthorisation(transaction);
    }
  }
  saveTransaction(transaction, function(successful) {
		if (successful) {
			// save the barcode in a separate list for double scans
			// Todo once we refactor the barcode property to be whatever was scanned, we should only need to use that.
			switch(barcodeObject.vesselBarcodeType) {
				case VesselBarcodeType.scotch:
				case VesselBarcodeType.tun:
					// The tun also uses the barcode field, but not sure it is ever set - see barcodeObject.js
					movement_casks.push(barcodeObject.barcode);
					previous_scan = barcodeObject.barcode;
					break;
				case VesselBarcodeType.pallet:
					movement_casks.push(barcodeObject.pallet.label);
					previous_scan = barcodeObject.pallet.label;
					break;
				default:
					movement_casks.push(barcodeObject.caskId);
					previous_scan = barcodeObject.caskId;
					break;
			}
		}
		callback(successful);
	});
}

function raiseAuthorisation(transaction) {
	"use strict";
	var authorisation = localStorage.getObject("runtime/authorisation"); // todo if it didnt exist we get an error.
	authorisation.transactionId = transaction.transactionId + "-AUTH";
	authorisation.allocation = pickingAllocation.number;
	authorisation.barcode = (transaction.barcode)? transaction.barcode : '';
	authorisation.caskId = (transaction.caskId)? transaction.caskId : '';
	authorisation.line = (transaction.disgorgeLine)? transaction.disgorgeLine : '';
	localStorage.setObject("runtime/authorisation",authorisation);
}

function saveAuthorisationTransaction(authorisation,callback) {
  "use strict";

	var transaction = createBaseTransaction({
		transactionId: authorisation.transactionId,
		'userNo': authorisation.user,
		transactionCode: "AUTH",
		incidentCode: String(authorisation.incident),
		status: "Error",
		barcode: authorisation.barcode,
		caskId: authorisation.caskId,
		requestNumber: authorisation.allocation,
		scanIndicator: "M",
		disgorgeLine: authorisation.line
	});

  saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description Saves a location incident transaction.
 * @param locationEntered
 * @param incidentCode
 * @param incidentReasons
 * @param callback
 */
function saveLocationIncidentTransaction(locationEntered, incidentCode, incidentReasons, callback) {
  "use strict";
  var thisIncident = getIncidentById(parseInt(incidentCode));
  var incidentText = thisIncident ? thisIncident.description : '';

  var saveLocationIncident = function(locationEntered,incidentCode, incidentText, incidentReasons) {
		var transaction = createBaseTransaction({
			transactionCode: "LOCISSUE",
			incidentCode: incidentCode,
			errorText: incidentText,
			status: "Error",
			barcode: "",
			caskId: "",
			warehouse: locationEntered.warehouse,
			bay: locationEntered.bay,
			rack: locationEntered.rack,
			level: locationEntered.level,
			locationIndicator: "M",
			reasonCodes: incidentReasons
		});

    saveTransaction(transaction, function(stored) {
			callback(stored);
		});
  };

  if(thisIncident.restricted === true) {
    console.log("restricted");
    showAlert("Authorisation Required.",{type:"info",
      auth:true,
      authUser:getSessionUser(),
      approvalRequired:(localStorage.getVariable("settings/useIncidentApproval") === "true"),
      canClose:true,callback:function(passed){
      if(passed) {
        saveLocationIncident(locationEntered,incidentCode,incidentText, incidentReasons);
      }
    }});
  } else {
    saveLocationIncident(locationEntered,incidentCode,incidentText, incidentReasons);
  }
}

/**
 * @description Saves a cask incidents recorded. Doesn't push to movement_casks
 * @param barcodeObject
 * @param incidentCode
 * @param incidentReasons
 * @param scanned
 * @param callback
 */
function saveCaskIncidentTransaction(barcodeObject, incidentCode, incidentReasons, scanned, callback) {
  "use strict";

	var thisIncident = getIncidentById(parseInt(incidentCode));
	var incidentText = thisIncident ? thisIncident.description : '';

	var saveIncidentTransaction = function(barcodeObject, incidentCode, incidentText) {
		var transaction = createBaseTransaction({
			transactionCode: "INCIDENT",
			barcode: barcodeObject.barcode,
			caskId: barcodeObject.caskId,
			status: "Error",
			incidentCode: String(incidentCode),
			reasonCodes: incidentReasons,
			errorText: incidentText,
			scanIndicator: scanned? "S":"M"
		});

		//last_incident Global from app.js
		setLastIncident(barcodeObject.barcode, barcodeObject.caskId, incidentCode);

		saveTransaction(transaction, function(success) {
			callback(success);
		});
	};

	if(thisIncident.restricted === true) {
		console.log("restricted");
		showAlert("Authorisation Required.",{type:"info",
			auth:true,
			authUser:getSessionUser(),
			approvalRequired:(localStorage.getVariable("settings/useIncidentApproval") === "true"),
			canClose:true,callback:function(passed){
				if(passed) {
					saveIncidentTransaction(barcodeObject,incidentCode,incidentText);
				}
			}});
	} else {
		saveIncidentTransaction(barcodeObject,incidentCode,incidentText);
	}
}

function saveAllocationIncidentTransaction(barcodeObject, incidentCode, scanned, callback) {
	"use strict";

	var thisIncident = getIncidentById(parseInt(incidentCode));
	var incidentText = thisIncident ? thisIncident.description : '';

	var saveIncidentTransaction = function(barcodeObject, incidentCode, incidentText) {
		var transaction = createBaseTransaction({
			barcode: barcodeObject.barcode,
			caskId: barcodeObject.caskId,
			status: "Error",
			incidentCode: String(incidentCode),
			errorText: incidentText,
			scanIndicator: scanned? "S":"M",
			requestNumber: pickingAllocation.number
		});

		//last_incident Global from app.js todo do we need this.
		setLastIncident(barcodeObject.barcode, barcodeObject.caskId, incidentCode);

		saveTransaction(transaction, function(success) {
			callback(success);
			switch(barcodeObject.vesselBarcodeType) {
				case VesselBarcodeType.scotch:
				case VesselBarcodeType.tun:
					// The tun also uses the barcode field, but not sure it is ever set - see barcodeObject.js
					previous_scan = barcodeObject.barcode;
					break;
				case VesselBarcodeType.pallet:
					previous_scan = barcodeObject.pallet.label;
					break;
				default:
					previous_scan = barcodeObject.caskId;
					break;
			}
		});
	};

	if (thisIncident.restricted === true) {
		console.log("restricted");
		showAlert("Authorisation Required.",{type:"info",
			auth:true,
			authUser:getSessionUser(),
			approvalRequired:(localStorage.getVariable("settings/useIncidentApproval") === "true"),
			canClose:true,callback:function(passed){
				if(passed) {
					saveIncidentTransaction(barcodeObject,incidentCode,incidentText);
				}
			}});
	} else {
		saveIncidentTransaction(barcodeObject,incidentCode,incidentText);
	}
}

/**
 * @description Save a transaction for the label replace screen.
 * @param {Object} oldBarcode Barcode object of the original label
 * @param {Object} newBarcode Barcode object of the new label.
 * @param callback
 */
function saveLabelReplaceTransaction(oldBarcode, newBarcode, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		scanIndicator: newBarcode.entryType,
		newCaskId: newBarcode.caskId,
		caskId: oldBarcode.caskId
	});

	saveTransaction(transaction, function(success) {
		if (success) {
			addToMovementCasks(oldBarcode);
		}
		callback(success);
	});
}

/**
 * @description saves the trailer receipt from scanLocation.warehouse and LS - trailer details
 * @param callback
 */
function saveTrailerReceiptTransaction(callback) {
	"use strict";
	if(!isSessionValid()) {
		callback(false);
		return;
	}

	//save as transaction as usual. Create the base transaction and add other appropriate values.
	var transaction = createBaseTransaction({});

	transaction.scanIndicator = "S";
	transaction.warehouse = scanLocation.warehouse;

	if(localStorage.getItem("scanTrailer")) {
		transaction.trailerId = localStorage.getItem("scanTrailer");
	}

	saveTransaction(transaction, function(successful) {
		callback(successful);
	});
}


/**
 * @description saves the barcode, caskId, and transaction details for dual labelling (Wood Tracking)
 * @param {string} barcode     - The barcode scanned
 * @param {string} barrelId    - The barrelId scanned
 * @param {String} indicator   - S or M for scan type
 * @param {Object} errorStatus - the error object produced from picking lists
 * @param {String} eventCode   - An event code to save against the transaction
 * @param callback
 */
function saveDualLabelTransaction(barcode, barrelId, indicator, errorStatus, eventCode, callback) {
	"use strict";
	if(!isSessionValid()) {
		callback(false);
		return;
	}

	//save barcode as transaction. Create the base transaction and add other appropriate values.
	var transaction = createBaseTransaction({});

	transaction.scanIndicator = indicator;
	transaction.barcode = barcode;
	transaction.caskId = barrelId;
	transaction.eventCode = eventCode;

	saveTransaction(transaction, function(successful) {
		if (successful) {
			// we don't save the barcode in a separate list for double scans here as the list for dual scans is
			// retained in the dsduallabel.php js to allow corrections etc.
			previous_scan = barrelId; // previous SWA label held locally in dsduallabel.php but the caskid saved here
		}
		callback(successful);
	});
}


/**
 * @description Saves a container receipt transaction. This is customised, so that the movement_casks global is not updated.
 * This would cause the rescanning functionality of container receipt to break if it was.
 * @param {Object} barcodeObject
 * @param {String} woodCode
 * @param {Number} caskType
 * @param {String} conditionCode
 * @param callback
 */
function saveContainerReceiptTransaction(barcodeObject, woodCode, caskType, conditionCode, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		warehouse: scanLocation.warehouse,
		bay: scanLocation.bay,
		rack: scanLocation.rack,
		level: scanLocation.level,
		direction: scanLocation.direction,
		locationIndicator: scanLocation.scanned,
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
		caskType: caskType,
		woodCode: woodCode,
		condition: conditionCode,
		containerRef: scanningContainer.containerRef
	});

	saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description Object defining the actions that can be scanned against a wood despatch
 * @type {Object}
 */
var WoodDespatchTransactionCodes = Object.freeze({"add": "DESPWOOD", "remove": "REMWOOD"});

/**
 * @description Saves a wood despatch transaction, based on the action taken.
 * @param barcodeObject
 * @param despatchId
 * @param transactionCode
 * @param callback
 */
function saveWoodDespatchTransaction(barcodeObject, despatchId, transactionCode, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
		despatchId: despatchId,
		transactionCode: transactionCode
	});

	saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description saves a transaction, specific to filling operations
 */
function saveFillingTransaction(barcodeObject, scotchBarcodeObject, fillingOptions, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		warehouse: fillingOptions.fillingWarehouse,
		locationIndicator: scanLocation.scanned,
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
		caskType: fillingOptions.caskType,
		woodCode: fillingOptions.woodCode,
		fillingLine: fillingOptions.fillingLine,
		spiritType: fillingOptions.spirit,
		blendCode: fillingOptions.blend,
		firstCask: fillingOptions.firstCaskNumber,
		flowLogId: fillingOptions.flowLogId
	});

	// If the filling is dual labelling also, then we need to add the scans SWA information.
	if (scotchBarcodeObject) {
		transaction.barcode = scotchBarcodeObject.barcode;
	}

	// If the filling is for pallets, and the pallet linking is turned on there will be a pallet stored.
	if(localStorage.getItem("currentPallet")) {
		// If using real pallets set the palletNo property else set the virtual scannerPallet property
		if (areRealPalletsInUse()) {
			transaction.palletNo = localStorage.getObject("currentPallet").number;
		} else {
			transaction.scannerPallet = localStorage.getObject("currentPallet").number;
		}
	}

	saveTransaction(transaction, function(success) {
		if (success) {
			addToMovementCasks(barcodeObject);
		}
		callback(success);
	});
}

/**
 * @description Save a return transaction from Wood Tracking Returns screen
 * @param {Object} barcodeObject - The barcode object scanned
 * @param {String} returnCode - The acceptance code, either Accept or Reject
 * @param callback
 */
function saveReturnTransaction(barcodeObject, returnCode, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
		eventCode: returnCode
	});

	saveTransaction(transaction, function(success) {
		if (success) {
			addToMovementCasks(barcodeObject);
		}
		callback(success);
	});
}

/**
 * @description Save an event transaction from the events screen.
 * @param {Object} barcodeObject - The barcode object scanned
 * @param {String} eventCode - The event code to be raised.
 * @param callback
 */
function saveEventTransaction(barcodeObject, eventCode, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
		eventCode: eventCode
	});

	saveTransaction(transaction, function(success) {
		callback(success);
	});
}


/**
 * @description saves a tun transaction, used for the filling and dumping of tuns
 * @param {String} locationCode The location code of the tun
 * @param {String} warehouseCode The Warehouse code of the tun
 * @param {Number}tunNumber The tun number, unique by warehouse
 * @param {String} indicator Scan indicator, M - manual, S - scanned barcode
 * @param {Object|null} errorStatus An error object if not valid in an allocation, null otherwise.
 * @param {Boolean} partialChange true if the tun has been part filled or dumped.
 * @param callback
 */
function saveTunTransaction(locationCode, warehouseCode, tunNumber, indicator, errorStatus, partialChange, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		warehouse: warehouseCode,
		vesselNo: tunNumber,
		scanIndicator: indicator,
		partial: partialChange,
		requestNumber: pickingAllocation.number,
		location: locationCode
	});

	if (errorStatus) {
		// we must have an error.
		transaction.status = "Error";
		transaction.incidentCode = errorStatus.code;
		transaction.errorText = errorStatus.text;

		raiseAuthorisation(transaction);
	}

	saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description Save a transaction specific to Drain and Fill from dsdrainfill.php
 * @param barcodeObject - Standard barcode object
 * @param errorStatus - Standard allocation error status if an error has occured.
 * @param state - The scanning state object on the drain and fill screen
 * @param callback - The function to run once the transaction is saved.
 */
function saveDrainFillTransaction(barcodeObject, errorStatus, state, callback) {
    "use strict";

    // palletNo - The pallet number currently being scanned on / off
    // transactionCode - The transaction code, which changes depending on draining or filling.
	var transaction = createBaseTransaction({
		transactionCode: state.transactionCode,
		scanIndicator: barcodeObject.entryType,
		caskId: barcodeObject.caskId,
        barcode: barcodeObject.barcode,
		requestNumber: pickingAllocation.number,
		palletNo: state.palletNo
	});

	// Only add the disgorge line to the transaction when in disgorge mode.
	if (state.mode === "drain") {
		transaction.disgorgeLine = localStorage.getItem("disgorgeline");
	}

	if (errorStatus) {
		// we must have an error.
		transaction.status = "Error";
		transaction.incidentCode = errorStatus.code;
		transaction.errorText = errorStatus.text;

		raiseAuthorisation(transaction);
	}

	saveTransaction(transaction, function(success) {
		// If we aren't doing the disgorge, then we track the cask to prevent rescanning.
		if (success && state.mode !== "drain") {
			switch(barcodeObject.vesselBarcodeType) {
				case VesselBarcodeType.scotch:
					movement_casks.push(barcodeObject.barcode);
					previous_scan = barcodeObject.barcode;
					break;
				default:
					movement_casks.push(barcodeObject.caskId);
					previous_scan = barcodeObject.caskId;
					break;
			}
		}
		callback(success);
	});
}

/**
 * @description Save a transaction for Filling batch scanning
 * @param {Object} caskObject - Standard barcode object
 * @param callback - Function to run when the save completes and transaction is stored.
 */
function saveFillingBatchTransaction(caskObject, callback) {
	"use strict";
	var transaction = createBaseTransaction({
		scanIndicator: caskObject.entryType,
		caskId: caskObject.caskId,
		batchId: scanningFillingBatch.batchId
	});

	saveTransaction(transaction, function(success) {
		callback(success);
	});
}

/**
 * @description Counts the number of transactions stored in LocalStorage
 * @returns {Number}
 */
function countStoredTransactions(completeCallback) {
	"use strict";
  localforage.length().then(function(numberOfKeys) {
    completeCallback(numberOfKeys);
  });
}

/**
 * @description Creates the basic object structure of a transaction
 * @returns {Object}
 */
function createBaseTransaction(additionalTransactionObject) {
	"use strict";
	var dateTimeObject = createTransactionDateTime(),
		id = createTransactionId(dateTimeObject);

	var transactionBase =  {
		company: getScannerCompany(),
		scannerId: getScannerId(),
		transactionId: id,
		transactionCode: getProgramTransactionCode().toUpperCase(),
		transactionDate: dateTimeObject.dateString,
		transactionTime: dateTimeObject.timeString,
		'userNo': getSessionUserId(),
		location: getScannerLocationCode(),
		telemetry: createTransactionTelemetry()
	};

	// merge if the parameter is an object and is not null.
	if (typeof additionalTransactionObject === 'object' && additionalTransactionObject !== null) {
		for(var key in additionalTransactionObject) {
			if (additionalTransactionObject.hasOwnProperty(key)) {
				transactionBase[key] = additionalTransactionObject[key];
			}
		}
	}

	// Return the bare bones of a transaction, merged with custom properties passed in
	return transactionBase;
}

/**
 * @description Creates a dateTime object made up of the original date, and string representations for DRAMS
 * @returns {{fullDate: Date, dateString: string, timeString: string, timeStringWithMilliseconds: string}}
 */
function createTransactionDateTime() {
  "use strict";
  var today = new Date();
  var dateString = today.getFullYear() + "-" + padNumber(2, today.getMonth() + 1) + "-" + padNumber(2, today.getDate());
  var timeString = padNumber(2,today.getHours()) + ":" + padNumber(2,today.getMinutes()) + ":" + padNumber(2, today.getSeconds());
  var timeStringWithMilliseconds = timeString + ":" + padNumber(3,today.getMilliseconds());
  return {
    fullDate: today,
    dateString: dateString,
    timeString: timeString,
    timeStringWithMilliseconds: timeStringWithMilliseconds
  };
}

/**
 * @description Creates a DRAMS barcode transaction id
 * @param {Object} dateTimeObject A dateTime object created by the createTransactionDateTime function
 * @returns {String} id
 */
function createTransactionId(dateTimeObject) {
  "use strict";
  var date = dateTimeObject.dateString.replace(/-/g, "");
  var time = dateTimeObject.timeStringWithMilliseconds.replace(/:/g, "");
  var scannerId = padNumber(5, getScannerId());
  return date + time + scannerId + getSessionUser();
}

/**
 * @description Creates a telemetry string to be saved along with the transaction
 * @return {string} Contains the Program (Page), the Menu Item the user chose, and if the application thinks its online.
 */
function createTransactionTelemetry() {
  "use strict";
  var program = getCurrentPage();
  var menuItem = getCurrentProgramKey();
  var isOnline = getApplicationNetworkState();

  return "PAGE:" + program + "|MENU:" + menuItem + "|ONLINE:" + isOnline;
}

/**
 * @description Sends transactions to the server. If successful then the remove function is called.
 * @param {Boolean} notify If a notification should be shown on screen.
 */
function sendTransactions(notify) {
	"use strict";
  countStoredTransactions(function(numberOfTransactions) {

    if(numberOfTransactions < 1) {
      console.log("No transactions cached to send.");
      return;
    }
    var ttBarcodeTrans = [];

    localforage.iterate(function(value) {
      ttBarcodeTrans.push(value);
    }).then(function() {
      //send
      if(notify) {
        var alertCode = '<div class="alert alert-info notification" role="alert" id="transactionNotification">' +
          'Sending cached transactions...' +
          '</div>';
        $("#footer").after(alertCode);
        $("#transactionNotification").fadeTo(2000, 500).slideUp(500, function() {
          $("#transactionNotification").alert('close');
        });
      }

      sending = true;
      var ttTransHeader = [];

      var transHeader = {
        scanner: getScannerId(),
        token: localStorage.getItem("scannertoken"),
        company: getScannerCompany()
      };
      ttTransHeader.push(transHeader);

			// Here we check to convert old transactions to new ones too.
			// This ensures existing transactions will upload when upgrading
      var dsObjects = {
        ttTransHeader: ttTransHeader,
        ttBarcodeTrans: convertTransactions(ttBarcodeTrans)
      };

      var sendingData = {dsBarcodeTrans: dsObjects};
      var sendingString = JSON.stringify(sendingData);

      console.log(ttBarcodeTrans.length + " transactions found and ready to send.");
      console.log("Timeout set to " + requestTimeout + "ms");
      var startTime = new Date().getTime();

      $.ajax({
        url: getRequestPath("transaction"),
        type: "POST",
        contentType: "application/json; charset=UTF-8",
        crossDomain: true,
        timeout:requestTimeout,
        xhrFields: {withCredentials: true},
        data: sendingString,
        success: function (data) {
          var endTime = new Date().getTime();
          var transferTime = endTime - startTime;
          console.log("Transaction transfer completed in " + transferTime + "ms");
          var responseData = data;

          if (responseData.transactions === null) {
            showAlert("Server processing error. No transactions returned");
            sending = false;
            return;
          }

          var successfulTransactions = responseData.transactions.split(",");
          console.log(successfulTransactions.length + " transactions saved on the server.");
          removeSuccessfulTransactions(successfulTransactions);
          sending = false;
          //update screens if on login or Menu.
          var currentPage = getCurrentPage();
          if (currentPage === "dslogin" || currentPage === "dsmenu") {
            //$("#transactioninfo").val("Transactions: " + countStoredTransactions());
            countStoredTransactions(function(count) {
              $("#transactioninfo").val("Transactions: " + count);
            });
          }
        },
        error: function (xhr) {

          var endTime = new Date().getTime();
          var transferTime = endTime - startTime;
          console.log("Sending attempt exhausted after " + transferTime + "ms");
          if(transferTime >= requestTimeout) {
            console.log("504 Gateway Timeout.");
            notifyOfTransactionSendingError(504);
          } else {
            if(xhr.status !== 0) {
              notifyOfTransactionSendingError(xhr.status);
            }
          }
          console.log("Failed server request " + xhr.status + ". Unable to transfer transactions.");
          sending = false;
        }
      });

    }).catch(function(err) {
      console.log(err);
    }); //iterating transactions
  }); // count stored transactions
}

/**
 * @description Converts any transactions saved before upgrade to new format.
 * @param transactionCollection Array of transactions to be checked and converted to new format if needed.
 * @returns {[]}
 */
function convertTransactions(transactionCollection) {
	"use strict";
	var outputTransactions = [];
	for (var i= 0; i<transactionCollection.length; i++) {
		if (transactionCollection[i].hasOwnProperty('tranid')) {
			// tranid property exists, therefore this is an old type transaction. It needs conversion.
			outputTransactions.push({
				transactionId: transactionCollection[i].tranid,
				company: (transactionCollection[i].coyno)? transactionCollection[i].coyno : 1,
				scannerId: (transactionCollection[i].scannerid)? transactionCollection[i].scannerid : 0,
				transactionCode: (transactionCollection[i].trcode)? transactionCollection[i].trcode : '',
				transactionDate: (transactionCollection[i].trandate)? transactionCollection[i].trandate : '',
				transactionTime: (transactionCollection[i].trantime)? transactionCollection[i].trantime : '',
				userNo: (transactionCollection[i]['userid#'])? transactionCollection[i]['userid#'] : 0,
				location: (transactionCollection[i].location)? transactionCollection[i].location : '',
				warehouse: (transactionCollection[i].warehouse)? transactionCollection[i].warehouse : '',
				bay: (transactionCollection[i].bay)? transactionCollection[i].bay : '',
				rack: (transactionCollection[i].rack)? transactionCollection[i].rack : '',
				level: (transactionCollection[i].level)? transactionCollection[i].level : '',
				direction: (transactionCollection[i].frontbackind)? transactionCollection[i].frontbackind : '',
				scanIndicator: (transactionCollection[i].scanind)? transactionCollection[i].scanind : 'M',
				locationIndicator: (transactionCollection[i].locnscanind)? transactionCollection[i].locnscanind : 'M',
				barcode: (transactionCollection[i].barcode)? transactionCollection[i].barcode : '',
				caskId: (transactionCollection[i].caskid)? transactionCollection[i].caskid : '',
				newCaskId: (transactionCollection[i].newcaskid)? transactionCollection[i].newcaskid : '',
				eventCode: (transactionCollection[i].eventcode)? transactionCollection[i].eventcode : '',
				trailerId: (transactionCollection[i].trailerid)? transactionCollection[i].trailerid : '',
				batchId: (transactionCollection[i].batchid)? transactionCollection[i].batchid : '',
				make: (transactionCollection[i].make)? transactionCollection[i].make : '',
				despatchId: (transactionCollection[i].despid)? transactionCollection[i].despid : 0,
				container: (transactionCollection[i].container)? transactionCollection[i].container : '',
				sealOne: (transactionCollection[i].sealno1)? transactionCollection[i].sealno2 : '',
				sealTwo: (transactionCollection[i].sealno2)? transactionCollection[i].sealno2 : '',
				sealThree: (transactionCollection[i].sealno3)? transactionCollection[i].sealno3 : '',
				requestNumber: (transactionCollection[i].reqno)? transactionCollection[i].reqno : 0,
				disgorgeLine: (transactionCollection[i].lineno)? transactionCollection[i].lineno : '',
				status: (transactionCollection[i].transtat)? transactionCollection[i].transtat : '',
				incidentCode: (transactionCollection[i].incidentcode)? transactionCollection[i].incidentcode : '',
				errorText: (transactionCollection[i].errortext)? transactionCollection[i].errortext : '',
				woodCode: (transactionCollection[i].woodcode)? transactionCollection[i].woodcode : '',
				caskType: (transactionCollection[i].casktype)? transactionCollection[i].casktype : 0,
				condition: (transactionCollection[i].conditioncode)? transactionCollection[i].conditioncode : '',
				containerRef: (transactionCollection[i].containerRef)? transactionCollection[i].containerRef : '',
				fillingLine: (transactionCollection[i].flineno)? transactionCollection[i].flineno : '',
				spiritType: (transactionCollection[i].spirittype)? transactionCollection[i].spirittype : 0,
				blendCode: (transactionCollection[i].blcode)? transactionCollection[i].blcode : '',
				firstCask: (transactionCollection[i].firstcask)? transactionCollection[i].firstcask : 0,
				flowLogId: (transactionCollection[i].flowlogid)? transactionCollection[i].flowlogid : 0,
				fillYear: (transactionCollection[i].fillyear)? transactionCollection[i].fillyear : 0,
				vesselNo: (transactionCollection[i].vesnumber)? transactionCollection[i].vesnumber : 0,
				fillingLocation: (transactionCollection[i].flocn)? transactionCollection[i].flocn : '',
				partial: (transactionCollection[i].partfilldump)? transactionCollection[i].partfilldump : false,
				palletNo: (transactionCollection[i].palletno)? transactionCollection[i].palletno : 0,
				transactionType: (transactionCollection[i].trantype)? transactionCollection[i].trantype : 0,
				scannerPallet: (transactionCollection[i].scannerPallet)? transactionCollection[i].scannerPallet : ''
			});
		} else if(transactionCollection[i].hasOwnProperty('transactionId')) {
			outputTransactions.push(transactionCollection[i]);
		}
		// there is no final else, transactions that have neither property should not exist.
	}
	return outputTransactions;
}

/**
 * @description Removes transactions from the scanner, based on transaction IDs saved by the server.
 * @param {Object} transactions Array of transaction ids
 */
function removeSuccessfulTransactions(transactions) {
  "use strict";
  console.log("Removing successfully stored transactions");
  var counter = 0;

	var removalFunction = function() {
		counter ++;
	};

	var errorFunction = function(error) {
		console.log("Error removing transaction key: " + error);
	};

  for (var t = 0; t < transactions.length; t++) {
    localforage.removeItem(transactions[t]).then(
			removalFunction()
		).catch(
			errorFunction()
		);

  }
  console.log("Removed " + counter + " transactions from the scanner.");
}

function notifyOfTransactionSendingError(code) {
	"use strict";
  var alertCode = '<div class="alert alert-danger notification" role="alert" id="transactionNotification">' +
    "Couldn't send transactions (" + code + ')' +
    '</div>';
  $("#footer").after(alertCode);
  $("#transactionNotification").fadeTo(2000, 500).slideUp(500, function() {
    $("#transactionNotification").alert('close');
  });
}
